<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="400px"
    @close="close"
  >
    <el-form ref="form" label-width="120px" :model="form" :rules="rules">
      <vab-query-form>
        <vab-query-form-top-panel>
          <el-form-item label="" prop="jobNo" label-width="60px">
            <el-input
              type="textarea"
              style="width: 250px"
              :rows="10"
              v-model="form.jobNo"
              placeholder="运单号 / 柜号"
            ></el-input>
          </el-form-item>
        </vab-query-form-top-panel>
      </vab-query-form>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loadingExPort" @click="sync">
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { tmsDataSync } from '@/api/cmsApi'
export default {
  props: {},
  data() {
    return {
      title: '',
      dialogFormVisible: false,
      loadingExPort: false,
      form: {
        jobNo: '',
      },
      rules: {
        jobNo: [
          { required: true, trigger: 'blur', message: '请输入运单号或柜号' },
        ],
      },
    }
  },
  mounted() {},
  methods: {
    async show(row) {
      this.title = '运单同步'
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    async sync() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            this.loadingExPort = true
            const res = await tmsDataSync(this.form)
            this.loadingExPort = false
            this.close()
            this.jump(res.data)
          } catch (error) {
            this.loadingExPort = false
            this.$message.error('导出失败!')
          }
        }
      })
    },
    jump(data) {
      this.$router.push({
        name: 'taskManage',
        params: {
          taskNo: data,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
html body .el-tag + .el-tag,
html body[class*='vab-theme-'] .el-tag + .el-tag {
  margin-left: 0px;
}
.tag {
  border: 1px solid #dedede;
  height: 100px;
}
.sel-option {
  height: auto;
  max-height: 200px;
  overflow: auto;
  background-color: #fff;
  cursor: pointer;
  font-weight: normal !important;
  padding: 0 5px;
}
</style>